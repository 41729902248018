import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  declare messageTargets: HTMLElement[]
  declare messagesTarget: HTMLElement
  declare inputTarget: HTMLInputElement
  static targets = ['message', 'messages', 'input']
  token!: string
  sessionExpirationDate!: Date
  tokenExpirationTime!: Date
  connection!: WebSocket

  connect() {
    const { chatToken, chatSessionExpirationDate, chatTokenExpirationTime } = (
      this.element as HTMLElement
    ).dataset
    this.token = <string>chatToken
    this.sessionExpirationDate = new Date(<string>chatSessionExpirationDate)
    this.tokenExpirationTime = new Date(<string>chatTokenExpirationTime)
    this.initConnection()
    this.inputTarget.addEventListener('keydown', this.inputOnEnter.bind(this))
  }

  inputOnEnter(event: KeyboardEvent) {
    if (event.key === 'Enter') {
      this.sendMessage()
    }
  }

  sendMessage() {
    const message = this.inputTarget.value
    if (message && message.trim() !== '') {
      const payload = {
        Action: 'SEND_MESSAGE',
        // RequestId: 'OPTIONAL_ID_YOU_CAN_SPECIFY_TO_TRACK_THE_REQUEST',
        Content: message,
        Attributes: {},
      }
      this.connection.send(JSON.stringify(payload))
      this.inputTarget.value = ''
    }
  }

  initConnection = async () => {
    this.connection = new WebSocket(
      `wss://edge.ivschat.${import.meta.env.VITE_AWS_REGION}.amazonaws.com`,
      this.token
    )

    this.connection.onopen = (event) => {
      console.info('Connected to the chat room.')
      this.renderConnect()
    }

    this.connection.onclose = (event) => {
      this.renderDisconnect(event.reason)
    }

    this.connection.onerror = (event) => {
      console.error('Chat room websocket error observed:', event)
      this.renderMessage({ Content: 'Error connecting to chat room.' })
    }

    this.connection.onmessage = (event) => {
      const data = JSON.parse(event.data)
      const eventType = data['Type']

      switch (eventType) {
        case 'EVENT':
          console.info('Received event:', data)
          this.handleEvent(data)
          break
        case 'ERROR':
          console.info('Received error:', data)
          this.handleError(data)
          break
        case 'MESSAGE':
          console.info('Received message:', data)
          // eslint-disable-next-line no-case-declarations
          const messageType = data.Attributes?.message_type || 'MESSAGE'
          switch (messageType) {
            case 'STICKER':
              this.handleSticker(data)
              break
            default:
              this.handleMessage(data)
              break
          }
          break
        default:
          console.error('Unknown message received:', event)
      }
    }
  }

  handleEvent(data: any) {
    throw new Error('Function not implemented.')
  }

  handleError(data: any) {
    throw new Error('Function not implemented.')
  }

  handleSticker(data: any) {
    throw new Error('Function not implemented.')
  }

  handleMessage(data: any) {
    this.renderMessage(data)
  }

  renderMessage(msg: {
    Content: string
    Attributes?: Record<string, string>
    Sender: { Attributes: Record<string, string>; UserId: string }
  }) {
    const message = (<HTMLElement>(
      this.messageTargets[0].parentElement
    )).cloneNode(true) as HTMLElement
    const content = message.querySelector(
      '.chat-message--component-content'
    ) as HTMLElement
    const user = message.querySelector(
      '.chat-message--component-name'
    ) as HTMLElement

    content.innerHTML = msg.Content
    if (msg.Sender) {
      user.innerHTML = msg.Sender.Attributes.displayName
    } else {
      user.innerHTML = 'System'
    }
    this.messagesTarget.prepend(message)
  }

  renderConnect() {
    this.renderMessage({
      Content: 'Connected to chat',
    })
  }

  renderDisconnect(reason: string) {
    this.renderMessage({
      Content: `Disconnected from chat: ${reason}`,
    })
  }
}
