import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  declare menuTarget: HTMLElement
  declare iconTarget: HTMLElement
  static targets = ['menu', 'icon']

  isShow = false

  toggle(): void {
    this.isShow = !this.isShow
    if (this.isShow) {
      this.menuTarget.classList.remove('hidden')
      this.iconTarget.classList.add('rotate-90')
      this.iconTarget.classList.replace('text-gray-300','text-gray-400')
    } else {
      this.menuTarget.classList.add('hidden')
      this.iconTarget.classList.remove('rotate-90')
      this.iconTarget.classList.replace('text-gray-400','text-gray-300')
    }
  }
}
