import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  declare mobileTarget: HTMLElement
  declare fadeTarget: HTMLElement
  declare backdropTarget: HTMLElement
  static targets = ['mobile', 'fade', 'backdrop']
  isOpen = false

  connect(): void {
    if ((this.element as HTMLElement).dataset.open === 'true') {
      this.open()
    }
  }

  open(): void {
    this.isOpen = true
    this.fadeTarget.classList.remove('ease-in', 'opacity-0')
    this.fadeTarget.classList.add('ease-out', 'opacity-100')
    this.backdropTarget.classList.remove('ease-in', 'opacity-0')
    this.backdropTarget.classList.add('ease-out', 'opacity-75')
    this.mobileTarget.classList.remove('pointer-events-none')
  }

  close(): void {
    this.isOpen = false
    this.fadeTarget.classList.remove('ease-out', 'opacity-100')
    this.fadeTarget.classList.add('ease-in', 'opacity-0')
    this.backdropTarget.classList.remove('ease-out', 'opacity-75')
    this.backdropTarget.classList.add('ease-in', 'opacity-0')
    this.mobileTarget.classList.add('pointer-events-none')
  }

  toggle(): void {
    if (this.isOpen) {
      this.close()
    } else {
      this.open()
    }
  }
}
