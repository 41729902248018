import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  declare fadeTarget: HTMLElement
  declare btnTarget: HTMLElement
  declare backdropTarget: HTMLElement
  declare mobileTarget: HTMLElement
  static targets = ['btn', 'fade']
  isOpen = false
  docClickEventListener: EventListener = (event) => {
    if (this.isOpen && !event.target.closest('#' + this.element.id)) {
      this.close()
    }
  }

  connect(): void {
    document.addEventListener('click', this.docClickEventListener)
  }

  disconnect(): void {
    document.removeEventListener('click', this.docClickEventListener)
  }

  open(): void {
    this.isOpen = true

    this.btnTarget.classList.remove('text-gray-500')
    this.btnTarget.classList.add('text-gray-900')

    this.fadeTarget.classList.remove(
      '-translate-y-1',
      'duration-150',
      'ease-in',
      'opacity-0'
    )
    this.fadeTarget.classList.add(
      'translate-y-0',
      'duration-200',
      'ease-out',
      'opacity-100'
    )
  }

  close(): void {
    this.isOpen = false

    this.btnTarget.classList.remove('text-gray-900')
    this.btnTarget.classList.add('text-gray-500')

    this.fadeTarget.classList.add(
      '-translate-y-1',
      'duration-150',
      'ease-in',
      'opacity-0'
    )
    this.fadeTarget.classList.remove(
      'translate-y-0',
      'duration-200',
      'ease-out',
      'opacity-100'
    )
  }

  toggle(): void {
    this.isOpen = !this.isOpen
    if (this.isOpen) {
      this.open()
    } else {
      this.close()
    }
  }
}
