import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  declare inputTarget: HTMLInputElement
  static targets = ['input']

  connect(): void {
    this.element.querySelectorAll('button[type="submit"]').forEach((button) => {
      (button as HTMLButtonElement).onclick = () => {
        const time = button.querySelector('time')?.dateTime
        if (time) {
          console.log(time)
          this.inputTarget.value = time
        }
      }
    })
  }
}
