import { Controller } from '@hotwired/stimulus'
import Tourguide from 'tourguidejs'

export default class extends Controller {
  tourguide: Tourguide

  connect() {
    this.tourguide = new Tourguide({
      src: this.element.getAttribute('data-src'),
    })
  }

  start(step = 1) {
    this.tourguide.start(step)
  }

  stop() {
    this.tourguide.stop()
  }

  next() {
    this.tourguide.next()
  }

  prev() {
    this.tourguide.prev()
  }

  go(step: number) {
    this.tourguide.go(step)
  }
}
