import { Controller } from '@hotwired/stimulus'
import { useTransition } from 'stimulus-use'

export default class extends Controller {
  declare menuTarget: HTMLElement
  declare toggleTransition: (event?: Event) => void
  declare enter: (event?: Event) => void
  declare leave: (event?: Event) => void
  declare transitioned: false
  static targets = ['menu']
  docClickEventListener: EventListener = (event: Event) => {
    if (!this.element.contains(event.target as HTMLElement)) {
      this.close()
    }
  }

  connect(): void {
    const { enterFrom, enterTo, leaveFrom, leaveTo } = (
      this.element as HTMLElement
    ).dataset
    useTransition(this, {
      element: this.menuTarget,
      enterFrom,
      enterTo,
      leaveFrom,
      leaveTo,
    })
  }

  disconnect(): void {
    document.removeEventListener('click', this.docClickEventListener)
  }

  toggle(): void {
    if (this.transitioned) {
      this.close()
    } else {
      this.open()
    }
  }

  open() {
    this.enter()
    document.addEventListener('click', this.docClickEventListener)
  }

  close(): void {
    this.leave()
  }
}
