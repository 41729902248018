import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  declare tabItemTargets: HTMLElement[]
  declare badgeItemTargets: HTMLElement[]
  declare hasBadgeItemTarget: boolean
  static targets = ['tabItem', 'badgeItem']

  switch(e: Event) {
    this.activeItem(e.target, this.tabItemTargets)

    if (this.hasBadgeItemTarget) {
      const badge = (e.target as HTMLElement)?.querySelector(
        "[tab--component-target='badgeItem']"
      )
      this.activeItem(badge, this.badgeItemTargets)
    }
  }

  // TODO: move below code into mixin so we can reuse it in other components
  activeItem(activeElement: EventTarget | null, elements: HTMLElement[]) {
    if (!(activeElement instanceof HTMLElement)) return

    const activeClasses = activeElement.dataset.activeClasses
    const inactiveClasses = activeElement.dataset.inactiveClasses

    elements.forEach((item) => {
      this.removeClass(item, activeClasses?.split(' '))
      this.addClass(item, inactiveClasses?.split(' '))
    })

    this.addClass(activeElement, activeClasses?.split(' '))
    this.removeClass(activeElement, inactiveClasses?.split(' '))
  }

  removeClass(element: HTMLElement, classes?: string[]) {
    classes && element.classList.remove(...classes)
  }

  addClass(element: HTMLElement, classes?: string[]) {
    classes && element.classList.add(...classes)
  }
}
