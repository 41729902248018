import { Controller } from "@hotwired/stimulus";
import { useTransition, useClickOutside } from 'stimulus-use'

export default class extends Controller {
  declare menuTarget: HTMLElement
  declare iconTarget: HTMLElement

  declare enter: (event?: Event) => void
  declare leave: (event?: Event) => void
  declare transitioned: false

  static targets = ['menu', 'icon']
  docClickEventListener: EventListener = (event: Event) => {
    if (!this.element.contains(event.target as HTMLElement)) {
      this.close()
    }
  }

  connect(): void {
    useTransition(this, { element: this.menuTarget })
    useClickOutside(this, { element: this.iconTarget })
  }

  disconnect(): void {
    document.removeEventListener('click', this.docClickEventListener)
  }

  open() {
    this.enter()
    document.addEventListener('click', this.docClickEventListener)
  }

  close(): void {
    this.leave()
    document.removeEventListener('click', this.docClickEventListener)
  }

  clickOutside(event) {
    event.preventDefault()
    this.close()
  }

  toggle(): void {
    if (this.transitioned) {
      this.close()
    } else {
      this.open()
    }
  }
}
