import { Controller } from '@hotwired/stimulus'

const chatClientToken = 'GENERATED_CHAT_CLIENT_TOKEN_HERE'
const socket = 'wss://edge.ivschat.us-west-2.amazonaws.com' // Replace “us-west-2” with supported region of choice.

export default class extends Controller {
  connection = new WebSocket(socket, chatClientToken)

  connect() {
    /*
    1. To connect to a chat room, you need to create a Secure-WebSocket connection
    using the client token you created in the previous steps. Use one of the provided 
    endpoints in the Chat Messaging API, depending on your AWS region.
    */

    /*
    3. To listen to incoming chat messages from this WebSocket connection 
    and display them in your UI, you must add some event listeners.
    */
    this.connection.onmessage = (event) => {
      const data = JSON.parse(event.data)
      this.displayMessage({
        display_name: data.Sender.Attributes.DisplayName,
        message: data.Content,
        timestamp: data.SendTime,
      })
    }
  }

  sendMessage() {
    /*
    2. You can send your first message by listening to user input 
    in the UI and sending messages to the WebSocket connection.
    */
    const payload = {
      Action: 'SEND_MESSAGE',
      RequestId: 'OPTIONAL_ID_YOU_CAN_SPECIFY_TO_TRACK_THE_REQUEST',
      Content: 'text message',
      Attributes: {
        CustomMetadata: 'test metadata',
      },
    }
    this.connection.send(JSON.stringify(payload))
  }

  displayMessage(message) {
    // Modify this function to display messages in your chat UI however you like.
    console.log(message)
  }

  /*
  4. Delete a chat message by sending the DELETE_MESSAGE action to the WebSocket 
  connection. The connected user must have the "DELETE_MESSAGE" permission to 
  perform this action.
  */
  deleteMessage(messageId) {
    const deletePayload = {
      Action: 'DELETE_MESSAGE',
      Reason: 'Deleted by moderator',
      Id: '${messageId}',
    }
    this.connection.send(deletePayload)
  }
}
