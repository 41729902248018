import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  declare fadeTargets: HTMLElement[]
  declare slideTarget: HTMLElement
  declare mobileTarget: HTMLElement
  static targets = ['mobile', 'fade', 'slide']
  isOpen = false

  initialize(): void {
    this.isOpen = false
  }

  open(): void {
    this.isOpen = true
    this.fadeTargets.forEach((t: HTMLElement) => {
      t.classList.remove('opacity-0')
      t.classList.add('opacity-100')
    })
    this.slideTarget.classList.remove('-translate-x-full')
    this.slideTarget.classList.add('-translate-x-0')
    this.mobileTarget.classList.remove('pointer-events-none')
  }

  close(): void {
    this.isOpen = false
    this.fadeTargets.forEach((t: HTMLElement) => {
      t.classList.remove('opacity-100')
      t.classList.add('opacity-0')
    })
    this.slideTarget.classList.remove('-translate-x-0')
    this.slideTarget.classList.add('-translate-x-full')
    this.mobileTarget.classList.add('pointer-events-none')
  }

  toggle(): void {
    this.isOpen = !this.isOpen
    this.fadeTargets.forEach((t: HTMLElement) => {
      t.classList.toggle('opacity-100')
      t.classList.toggle('opacity-0')
    })
    this.slideTarget.classList.toggle('-translate-x-0')
    this.slideTarget.classList.toggle('-translate-x-full')
    this.mobileTarget.classList.toggle('pointer-events-none')
  }
}
