import { Controller } from '@hotwired/stimulus'
import tinymce from 'tinymce'
import '@tinymce/tinymce-webcomponent'
/* Default icons are required. After that, import custom icons if applicable */
import 'tinymce/icons/default'

/* Required TinyMCE components */
import 'tinymce/themes/silver'
import 'tinymce/models/dom'

/* Import plugins */
import 'tinymce/plugins/advlist'
import 'tinymce/plugins/code'
import 'tinymce/plugins/emoticons'
import 'tinymce/plugins/emoticons/js/emojis'
import 'tinymce/plugins/link'
import 'tinymce/plugins/lists'
import 'tinymce/plugins/table'

/* Import premium plugins */
/* NOTE: Download separately and add these to /src/plugins */
/* import './plugins/checklist/plugin'; */
/* import './plugins/powerpaste/plugin'; */
/* import './plugins/powerpaste/js/wordimport'; */

/* The default content CSS can be changed or replaced with appropriate CSS for the editor content. */
import contentCss from 'tinymce/skins/content/default/content.min.css?inline'

export default class extends Controller {
  connect(): void {
    tinymce.init({
      content_css: false,
      menubar: false,
      selector: 'textarea.text-editor',
      content_style: contentCss,
      body_class:
        'shadow-sm focus:ring-primary-500 focus:border-primary-500 block w-full sm:text-sm border-gray-300 rounded-md',
    })
  }
}
