import { Application } from '@hotwired/stimulus'
import { definitionsFromGlob } from 'stimulus-vite-helpers'
import { Datepicker } from 'stimulus-datepicker'

const application = Application.start()
application.register('datepicker', Datepicker)
const controllers = definitionsFromGlob(
  import.meta.globEager('./**/*_controller.ts')
)
const component_controllers = definitionsFromGlob(
  import.meta.globEager('../../components/**/*_controller.ts')
)
component_controllers.forEach((entry) => {
  entry.identifier =
    entry.identifier.substring(
      6, // "spui--".length
      entry.identifier.indexOf('component--')
    ) + '-component'
})

application.load(controllers)
application.load(component_controllers)

window.application = application
export default application
