import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  declare iconMenuTarget: HTMLElement
  declare mobileMenuTarget: HTMLElement
  declare iconXTarget: HTMLElement
  static targets = ['iconMenu', 'mobileMenu', 'iconX']

  isOpen = false

  open(): void {
    this.iconMenuTarget.classList.remove('block')
    this.iconMenuTarget.classList.add('hidden')
    this.iconXTarget.classList.remove('hidden')
    this.iconXTarget.classList.add('block')
    this.mobileMenuTarget.classList.remove('hidden')
  }

  close(): void {
    this.iconMenuTarget.classList.remove('hidden')
    this.iconMenuTarget.classList.add('block')
    this.iconXTarget.classList.remove('block')
    this.iconXTarget.classList.add('hidden')
    this.mobileMenuTarget.classList.add('hidden')
  }

  toggle(): void {
    this.isOpen = !this.isOpen
    this.isOpen ? this.open() : this.close()
  }
}
