import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  declare files: File[]
  static targets = ['dropZone', 'upload', 'filePreview', 'removeImg']

  onChange(e) {
    if(e.target.files.length > 0){
      this.removeListImg()
      this.files = [...e.target.files]
      this.files.forEach((file, i) => {
        const src = URL.createObjectURL(file)
        this.buildPreviewElement(src, i)
      })
    }
  }

  dragOverHandle(e) {
    e.preventDefault()
  }

  dropHandler(e) {
    e.preventDefault()
    if (e.dataTransfer.files) {
      this.removeListImg();
      this.files = [...e.dataTransfer.files]
      this.files.forEach((file, i) => {
        if (file.type.includes('image/')) {
          const src = URL.createObjectURL(file)
          this.buildPreviewElement(src, i)
        }
      })
    }
  }
 
  buildPreviewElement(imgSrc, id) {
    const imgWrapper = document.createElement('div')
    imgWrapper.classList.add('relative', 'w-48', 'p-2')
    imgWrapper.setAttribute('id', 'image-id-' + id)

    const imgRemove = this.closeElement(id)

    const img = document.createElement('img')
    img.src = imgSrc
    img.classList.add('w-full', 'object-contain')

    imgWrapper.appendChild(img)
    imgWrapper.appendChild(imgRemove)

    this.filePreviewTarget.appendChild(imgWrapper)
    this.filePreviewTarget.classList.remove('hidden')
  }

  closeElement(imgId) {
    const imgRemove = document.createElement('span');
    imgRemove.setAttribute('data-action', 'click->file-upload--component#removeImg');
    imgRemove.setAttribute('data-image-id', imgId);
    imgRemove.classList.add('w-5', 'absolute', 'top-0', 'right-0', 'bg-white', 'border', 'border-gray-400', 'text-gray-600');
    imgRemove.classList.add('rounded-full', 'flex', 'justify-center', 'items-center', 'cursor-pointer');

    const xIcon = document.createElementNS('http://www.w3.org/2000/svg', 'svg');
    const iconPath = document.createElementNS(
      'http://www.w3.org/2000/svg',
      'path'
    );

    xIcon.setAttribute('fill', 'none');
    xIcon.setAttribute('viewBox', '0 0 24 24');
    xIcon.setAttribute('stroke', 'currentColor');
    xIcon.setAttribute('stroke-width', '{1.5}');
    xIcon.classList.add('close-icon');

    iconPath.setAttribute('d', 'M6 18L18 6M6 6l12 12');
    iconPath.setAttribute('stroke-linecap', 'round');
    iconPath.setAttribute('stroke-linejoin', 'round');

    xIcon.appendChild(iconPath);
    imgRemove.appendChild(xIcon);

    return imgRemove
  }

  removeImg(e) {
    const imgId = e.target.parentNode.getAttribute('data-image-id') || e.target.parentNode.parentNode.getAttribute('data-image-id')
    const imgWrapper = document.getElementById('image-id-' + imgId)
    imgWrapper?.remove()

    this.files[imgId] = null
    this.uploadTarget.files = this.fileListItems(this.files)

    if (this.filePreviewTarget.innerHTML == '') {
      this.filePreviewTarget.classList.add('hidden')
    }
  }

  removeListImg() {
    this.filePreviewTarget.innerHTML = ''
    this.files = []
  }
  
  fileListItems(files: File[]) {
    const data = new DataTransfer()
    files.forEach((file) => {
      if(file) {
        data.items.add(file)
      }
    })
    return data.files
  }
}
