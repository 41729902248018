import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  declare listTarget: HTMLInputElement
  declare inputTarget: HTMLInputElement
  declare itemTargets: HTMLElement[]
  static targets = ['input', 'list', 'item']
  docClickEventListener: EventListener = (event) => {
    if (!event.target.closest('#' + this.element.id)) {
      event.preventDefault()
      this.inputTarget.value = this.selected
      this.close()
    }
  }
  selected: string | null = null
  active_index = 0

  connect() {
    this.itemTargets.forEach((t: HTMLElement) => {
      t.addEventListener('click', (e: Event) => {
        e.preventDefault()
        this.itemTargets.forEach((t: HTMLElement) => {
          t.classList.remove('active')
        })
        t.classList.add('active')
      })
      t.addEventListener('mouseenter', () => {
        t.classList.remove('text-gray-900')
        t.classList.add('text-white', 'bg-primary-600')
      })
      t.addEventListener('mouseleave', () => {
        t.classList.remove('text-white', 'bg-primary-600')
        t.classList.add('text-gray-900')
      })
    })
    if (this.inputTarget.value) {
      this.active_index = this.itemTargets.findIndex((t: HTMLElement) => {
        const spans = t.querySelectorAll('span')
        const match = spans[0].innerText === this.inputTarget.value
        if (match) {
          this.set_item_active(spans)
        }
        return match
      })
      this.selected = this.inputTarget.value
    }
  }

  disconnect(): void {
    document.removeEventListener('click', this.docClickEventListener)
  }

  open() {
    document.addEventListener('click', this.docClickEventListener)
    this.inputTarget.focus()
    this.inputTarget.setSelectionRange(-1, -1)
    this.listTarget.classList.remove('hidden')
  }

  close() {
    document.removeEventListener('click', this.docClickEventListener)
    this.listTarget.classList.add('hidden')
  }

  toggle() {
    this.inputTarget.focus()
    this.inputTarget.setSelectionRange(-1, -1)
    if (this.listTarget.classList.contains('hidden')) {
      document.addEventListener('click', this.docClickEventListener)
    } else {
      document.removeEventListener('click', this.docClickEventListener)
    }
    this.listTarget.classList.toggle('hidden')
  }

  filter() {
    const input = this.inputTarget.value.toLowerCase()
    this.itemTargets.forEach((t: HTMLElement) => {
      const spans = t.querySelectorAll('span')
      const text = spans[0].innerText.toLowerCase()
      if (text.includes(input)) {
        if (text == input) {
          this.set_item_active(spans)
          this.inputTarget.value = spans[0].innerText
          this.selected = spans[0].innerText
        }
        t.classList.remove('hidden')
      } else {
        spans[1].classList.add('hidden')
        t.classList.add('hidden')
      }
    })
  }

  select_item() {
    this.itemTargets.forEach((t: HTMLElement) => {
      t.classList.remove('text-white', 'bg-primary-600')
      t.classList.add('text-gray-900')
    })
    this.itemTargets[this.active_index].classList.remove('text-gray-900')
    this.itemTargets[this.active_index].classList.add(
      'text-white',
      'bg-primary-600'
    )
  }

  handle_key(event: Event) {
    const key = event.key
    if (key == 'ArrowUp') {
      if (this.active_index > 0) {
        this.active_index--
        this.select_item()
      }
    } else if (key == 'ArrowDown') {
      if (this.active_index < this.itemTargets.length - 1) {
        this.active_index++
        this.select_item()
      }
    } else if (key == 'Enter') {
      const target = this.itemTargets[this.active_index]
      const spans = target.querySelectorAll('span')
      this.set_item_active(spans)
      this.inputTarget.value = spans[0].innerText
      this.selected = spans[0].innerText
      this.close()
    }
  }

  set_item_active(spans: NodeListOf<HTMLSpanElement>) {
    this.itemTargets.forEach((t: HTMLElement) => {
      const s = t.querySelectorAll('span')
      s[0].classList.remove('font-semibold')
      s[1].classList.add('hidden')
    })

    spans[0].classList.add('font-semibold')
    spans[1].classList.remove('hidden')
  }

  select(event: Event) {
    const target = event.currentTarget as HTMLElement
    const spans = target.querySelectorAll('span')
    this.set_item_active(spans)
    this.inputTarget.value = spans[0].innerText
    this.selected = spans[0].innerText
    this.close()
  }
}
