import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static targets = [ "toggle", "enabledIcon", "disabledIcon", "togglePointer" ]

  connect(): void {
    this.switchToggleIcon(this.toggleTarget.dataset.toggleEnabled == 'true')
  }

  switch(e) {
    const target        = e.target
    const togglePointer = this.togglePointerTarget

    const targetDataset = target.dataset

    const disabledClass = targetDataset.disabledClass
    const enabledClass  = targetDataset.enabledClass

    const pointerDisabledClass = togglePointer.dataset.disabledClass
    const pointerEnabledClass  = togglePointer.dataset.enabledClass

    const toggleEnabled = targetDataset.toggleEnabled

    if (toggleEnabled == 'true') {
      this.removeClass(target, enabledClass)
      this.addClass(target, disabledClass)

      this.removeClass(togglePointer, pointerEnabledClass)
      this.addClass(togglePointer, pointerDisabledClass)

      target.dataset.toggleEnabled = 'false'
    } else {
      this.removeClass(target, disabledClass)
      this.addClass(target, enabledClass)

      this.removeClass(togglePointer, pointerDisabledClass)
      this.addClass(togglePointer, pointerEnabledClass)

      target.dataset.toggleEnabled = 'true'
    }

    this.switchToggleIcon(toggleEnabled == 'false')
  }

  removeClass(element, classes) {
    element.classList.remove(classes)
  }

  addClass(element, classes) {
    element.classList.add(classes)
  }

  switchToggleIcon(enabled) {
    if (!this.hasDisabledIconTarget || !this.hasEnabledIconTarget) return

    if(enabled){
      this.showToggleIcon(this.enabledIconTarget)
      this.hideToggleIcon(this.disabledIconTarget)
    }
    else {
      this.showToggleIcon(this.disabledIconTarget)
      this.hideToggleIcon(this.enabledIconTarget)
    }
  }

  showToggleIcon(toggleElement) {
    toggleElement.classList.remove('opacity-0', 'ease-out', 'duration-100')
    toggleElement.classList.add('opacity-100', 'ease-in', 'duration-200')
  }

  hideToggleIcon(toggleElement) {
    toggleElement.classList.remove('opacity-100', 'ease-in', 'duration-200')
    toggleElement.classList.add('opacity-0', 'ease-out', 'duration-100')
  }
}
