import { Controller } from '@hotwired/stimulus'

type DatasetStyles = {
  stylesChecked: string
  stylesUnchecked: string
  stylesActive: string | undefined
  stylesInactive: string | undefined
}

export default class extends Controller {
  declare labelTargets: HTMLElement[]
  declare inputTargets: HTMLInputElement[]
  declare iconTargets: HTMLElement[]
  declare borderTargets: HTMLElement[]
  static targets = ['label', 'input', 'icon', 'border']

  connect(): void {
    this.inputTargets.forEach((input) => {
      input.addEventListener('change', () => {
        this.select()
      })
    })
    this.set_styles()
  }

  select() {
    this.set_styles()
  }

  private style(target: HTMLElement, checked: boolean, active: boolean): void {
    if (!target) return

    const { stylesChecked, stylesUnchecked, stylesActive, stylesInactive } =
      target.dataset as DatasetStyles

    if (checked) {
      target.classList.remove(...stylesUnchecked.split(' '))
      target.classList.add(...stylesChecked.split(' '))
    } else {
      target.classList.remove(...stylesChecked.split(' '))
      target.classList.add(...stylesUnchecked.split(' '))
    }
    if (active) {
      stylesInactive &&
        target.classList.remove(...(<string>stylesInactive).split(' '))
      stylesActive && target.classList.add(...stylesActive.split(' '))
    } else {
      stylesActive && target.classList.remove(...stylesActive.split(' '))
      stylesInactive &&
        target.classList.add(...(<string>stylesInactive).split(' '))
    }
  }

  private set_styles() {
    this.labelTargets.forEach((radio, index) => {
      const checked = this.inputTargets[index].checked
      const active = document.activeElement === radio
      this.style(radio, checked, active)
      this.style(this.iconTargets[index], checked, active)
      this.style(this.borderTargets[index], checked, active)
    })
  }
}
