import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  connect() {
    setTimeout(() => {
      this.element.classList.remove(
        'opacity-0',
        'translate-y-2',
        'sm:translate-y-0',
        'sm:translate-x-2'
      )
      this.element.classList.add(
        'transform',
        'translate-y-0',
        'opacity-100',
        'sm:translate-x-0',
        'ease-out',
        'duration-300'
      )
    })
    const timeout = this.element.dataset.timeout
    if (timeout) {
      setTimeout(() => {
        this.close()
      }, timeout)
    }
  }

  close() {
    this.element.classList.remove('ease-out', 'duration-300')
    this.element.classList.add('ease-in', 'duration-100', 'opacity-0')
    setTimeout(() => {
      this.element.remove()
    }, 100)
  }
}
