import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = [ "colorPicker" ]

  onSelect(e) {
    const colorPickers = this.colorPickerTargets

    colorPickers.forEach((item) => {
      item.parentNode.classList.remove("ring", "ring-offset-1")
    })

    e.target.parentNode.classList.add("ring", "ring-offset-1")
  }
}
