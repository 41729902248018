import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  declare firstTarget: HTMLInputElement
  declare secondTarget: HTMLInputElement
  static targets = ['first', 'second']
  valid = true

  compare(): void {
    if (this.valid == (this.firstTarget.value === this.secondTarget.value))
      return

    this.valid = !this.valid
    if (this.valid) {
      this.secondTarget.classList.add(
        'focus:ring-primary-500',
        'focus:border-primary-500',
        'border-gray-300'
      )
      this.secondTarget.classList.remove(
        'border-red-300',
        'text-red-900',
        'placeholder-red-300',
        'focus:ring-red-500',
        'focus:border-red-500',
        'focus:outline-none'
      )
    } else {
      this.secondTarget.classList.add(
        'border-red-300',
        'text-red-900',
        'placeholder-red-300',
        'focus:ring-red-500',
        'focus:border-red-500',
        'focus:outline-none'
      )
      this.secondTarget.classList.remove(
        'focus:ring-primary-500',
        'focus:border-primary-500',
        'border-gray-300'
      )
    }
  }
}
