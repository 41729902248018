import { Controller } from '@hotwired/stimulus'
import { useTransition } from 'stimulus-use'

export default class extends Controller {
  declare mobileTarget: HTMLElement
  declare toggleTransition: (event?: Event) => void
  declare enter: (event?: Event) => void
  declare leave: (event?: Event) => void
  declare transitioned: false
  static targets = ['mobile']
  docClickEventListener: EventListener = (event: Event) => {
    if (!this.element.contains(event.target as HTMLElement)) {
      this.close()
    }
  }

  connect(): void {
    useTransition(this, { element: this.mobileTarget })
  }

  disconnect(): void {
    document.removeEventListener('click', this.docClickEventListener)
  }

  open() {
    this.enter()
    document.addEventListener('click', this.docClickEventListener)
  }

  close(): void {
    this.leave()
    document.removeEventListener('click', this.docClickEventListener)
  }

  toggle(): void {
    if (this.transitioned) {
      this.close()
    } else {
      this.open()
    }
  }
}
